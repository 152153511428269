<template>
  <div id="SignUp">
    <div class="main-theme">
      <img src="http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20230831164200686.png" />
    </div>
    <div class="form_view">
      <div class="mini_title"><span>报名须知</span></div>
      <div class="rule-cont">
        <div class="rule-list">
          <!-- <div class="rule-item">
            <span class="num">1</span>
            <div class="content">报名时间：2023/09/02-2023/09/10（暂定）</div>
          </div> -->
          <div class="rule-item"><span class="num">1</span>
            <div class="content">报名流程：
              <div class="fee-list">
                <p>1）店主提交身份信息</p>
                <p>2）线上缴纳2025年蜜蛋年会定位费、旅游定位费</p>
                <p>3）客服审核</p>
                <p>4）客服审核后确认报名(系统回执报名是否成功）</p>
                <p>5）店主报名审核通过以后方可为意向店主报名</p>
              </div>
            </div>
          </div>
          <div class="rule-item"><span class="num">2</span>
            <div class="content">报名类型支持:
              <div class="fee-list">
                <p>年会报名类型：</p>
                <p>1）店主</p>
                <p>2）意向店主</p>
                <p>旅游报名类型：</p>
                <p>1）店主</p>
                <p>2）意向店主</p>
              </div>
            </div>
          </div>
          <div class="rule-item"><span class="num">3</span>
            <div class="content">收费标准：
              <div class="fee-list">
                <p>1) 年会定位费: 店主 888/人 意向店主288/人</p>
                <p>2) 旅游定位费: 成人1380/人 儿童：身高＜1.2m，980/人；儿童≥1.2m，1380/人</p>
                <!-- <p>①年龄&lt;7 岁 500 元 （不占床）</p>
                <p>②年龄≥7 岁 990元 （占床）</p> -->
                <p>举例:成人小A报名参加年会并同时参加旅游，需线上缴纳888+1380，合计2268定位费</p>
              </div>
            </div>
          </div>
          <div class="rule-item"><span class="num">4</span>
            <!-- 店主报名审核通过后，18岁以下小孩家属、18岁以上成年家属方可继续报名。 -->
            <div class="content">年会和旅游报名同时开启，仅旅游定位费支持积分抵扣(1积分=5元现金 ）</div>
          </div>
          <div class="rule-item"><span class="num">5</span>
            <div class="content">
              参会活动说明
              <div class="fee-list">
                <p>1)活动期间，须承诺全程配合主办方的安排及流程，遵守相关管理规定，不得擅自行动，由此带来的不良后果，将由本人承担。</p>
                <p>2)您已完全阅读以上内容，确认符合以上须知条件、保证做到以上承诺，自愿报名参与活动。非特殊情况，已报名并缴纳费用后暂不可申请取消报名。因公司需提前进行旅游服务预定，已付酒店定位费不支持退款。</p>
                <p>3) 四川蜜蛋网络科技有限公司在法律规定的范围内对本次报名有最终解释权。</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="btns">
      <van-button round color="#E0BD90" @click="toList()" v-if="info.existsApplyCount > 0">我的报名</van-button>
      <van-button round color="#E0BD90" @click="toJion(1)" v-if="!info.existsEffectiveApply">即刻报名</van-button>
      <van-button round plain color="#E0BD90" @click="toJion(2)" v-else>继续报名</van-button>
    </div>

    <!-- 分享 -->
    <div class="share-layer" @click="showPoster = true">
      <div class="share-icon"><van-icon name="share" /></div>
      <div class="text">分享</div>
    </div>
    <poster :width="600" :height="892" :poster-list="posterList" :inapp="isInApp ? '1' : '0'" :page-path="pagePath" v-if="showPoster" @close="showPoster = false" />
  </div>
</template>

<script>
import yellowTitle from "./components/yellowTitle";
import { replaceAppToken, deliveryMessage } from "../../../utils/appUtil";
import Poster from '../../../components/Poster/Poster'

export default {
  name: "SignUp",
  components: {
    yellowTitle, Poster
  },
  data() {
    return {
      tabIndex: 0,
      arr: [],
      info: '',
      type: 1,
      isInApp: false,
      pagePath: this.baseHost + "/#/signUp",
      posterList: [
        {
          backgroundImage: 'http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20230901151245860.png',
          tempBgImage: null,
          textColor: '#ffffff',
          tempPoster: '',
          wxCode: { width: 240, point: [300, 630] }
        }],
      showPoster: false
    }
  },
  created() {
    let { token, inapp } = this.$route.query;
    if (token) {
      this.isInApp = true;
      replaceAppToken(token, () => {
        this.Init()
      });
    } else {
      if (inapp === '1') this.isInApp = true;
      this.Init()
    }
  },
  methods: {
    Init() {
      this.post('/OnlyOnce/AnnualMeeting/GetAgentDetails2025', {
        source: 1
      }, true, 2).then(json => {
        if (json.code === -1) return;
        if (json.code === 0) {
          return this.$dialog.alert({ title: '提示', message: json.msg }).then(() => {
            if (this.isInApp) deliveryMessage({ 'action': 'goHome' })
            else this.$router.push("/")
          })
        }
        let data = json.response;
        if (!data) {
          return this.$dialog.alert({ title: '提示', message: "该账号信息异常，请联系客服" }).then(() => {
            if (this.isInApp) deliveryMessage({ 'action': 'goHome' })
            else this.$router.push("/")
          })
        }
        if (!data.isAttachments) {
          return this.$dialog.confirm({
            title: '提示',
            message: '您还未完成实名认证，请先前往认证',
            confirmButtonText: '去认证',
            cancelButtonText: '返回'
          }).then(e => {
            window.location.href = this.baseHost + '/7999/Arongleweb/html/mycenter/realVerified.html'
          }).catch(e => {
            if (this.isInApp) deliveryMessage({ 'action': 'authentication' })
            else this.$router.back()
          })
        }
        this.info = data;
      })
    },
    toJion(e) {
      if (!this.info) {
        return this.$dialog.alert({ title: '提示', message: '网络繁忙，请稍后' })
      }
      let data = this.info;
      if (data.isCv && data.orderCount <= 0) {
        return this.$dialog.alert({
          title: "提示",
          message: "618活动期间，您需要至少有一张有效订单，才能参加报名。",
          confirmButtonText: "立即下单"
        }).then(() => {
          if (this.isInApp) deliveryMessage({ 'action': 'jumpShopkeeperOrder' })
          else window.location.href = this.baseHost + "/7999/Arongleweb/html/groupon/groupon_list.html"
        })
      }
      // this.$router.push(`/Recharge/InformationFill?type=${e}&inApp=` + (this.isInApp ? 1 : 0));
      window.location.href = this.baseHost + `/#/Recharge/InformationFill?type=${e}&inApp=` + (this.isInApp ? 1 : 0)
    },
    toList() {
      if (this.info.existsApply <= 0) {
        return this.$dialog.alert({
          title: '提示',
          message: '暂无任何报名信息'
        })
      }
      // this.$router.push(`/Recharge/AnnualMeetingList?inApp=` + (this.isInApp ? 1 : 0))
      window.location.href = this.baseHost + `/#/Recharge/AnnualMeetingList?inApp=` + (this.isInApp ? 1 : 0)
    },
  },
  mounted() {
  }
}
</script>

<style lang="scss">
#SignUp {
  width: 100%;
  font-size: 0px;
  padding-bottom: 30px;

  .main-theme {
    img {
      width: 100%;
    }
  }

  .back_img {
    width: 100%;
    position: relative;
    bottom: 64px;
  }

  .video_view {
    margin-left: 15px;
    margin-right: 15px;
    height: 185px;
    margin-top: 75px;
    background-color: #000;
    position: relative;
    /*position: absolute;*/
    /*z-index: 1;*/
    /*top: 96px;*/
    box-sizing: border-box;
    border: 5px solid #fff;
  }

  .form_view {
    font-size: 14px;
    background-color: #fff;
    position: relative;
    padding: 0 20px 30px;
    box-sizing: border-box;
    border-radius: 15px;
    padding-bottom: 12px;
  }

  .sub-theme {
    position: absolute;
    top: -40px;
    left: 0;
    right: 0;

    img {
      width: 288px;
    }
  }

  .mini_title {
    color: #d4b28a;
    text-align: center;
    position: relative;
    font-weight: bold;
    font-size: 18px;

    span {
      position: relative;
      z-index: 10;
      display: inline-block;
      padding: 11px 30px;
      background-color: #fff;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 280px;
      height: 1px;
      background-color: #d4b28a;
      left: calc(50% - 140px);
    }
  }

  .account-cont {
    display: flex;
    padding: 11px 0;
    align-items: center;
    justify-content: space-between;
  }

  .integral_box {
    display: flex;
    justify-content: center;
    font-size: 18px;
    flex-wrap: wrap;
    align-items: center;

    .integral_title {
      color: #666;
      width: 100%;
      font-size: 12px;
      line-height: 20px;
    }

    .integral_icon {
      width: 18px;
      height: 18px;
      margin-left: 5px;
      position: relative;
      bottom: 2px;
    }

    .integral_num {
      color: #f72fa8;
      font-weight: bold;
      font-size: 27px;
      position: relative;
      top: 2px;
    }
  }

  .fee-table {
    padding: 15px 0;
    text-align: left;

    .van-row {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #999999;

      .van-col {
        float: none;
        color: #666;
        padding: 10px;
        font-size: 12px;
        border-left: 1px solid #999;

        .item {
          margin: 0 -10px;
          padding: 10px;
          border-bottom: 1px solid #999;

          &:first-child {
            padding-top: 0;
          }

          &:last-child {
            padding-bottom: 0;
            border-bottom: none;
          }
        }

        &:first-child {
          border-left: none;
        }
      }

      &:first-child {
        border-top: 1px solid #999999;
      }
    }
  }

  .rule-cont {
    color: #666;
    font-size: 12px;
    text-align: left;
    line-height: 20px;
    letter-spacing: 1px;
    padding-bottom: 10px;

    .main-title {
      text-align: center;
      padding: 10px 0;
      color: #f0169a;
      font-size: 13px;
      font-weight: bold;
    }

    .van-divider {
      color: #666;
      font-size: 12px;
      margin: 10px 15%;
    }

    .explain {
      text-indent: 20px;
      padding-left: 21px;
    }

    .user-title {
      font-weight: bold;
      padding-left: 21px;
    }

    .rule-list {
      padding-bottom: 10px;

      .rule-item {
        display: flex;

        .num {
          width: 13px;
          height: 13px;
          color: #fff;
          font-size: 10px;
          border-radius: 50%;
          display: inline-flex;
          align-items: center;
          margin-right: 8px;
          justify-content: center;
          background-color: #e0bd90;
        }

        .content {
          flex: 1;
          color: #333333;
          font-size: 12px;
        }
      }
    }

    .gift-bag-content {
      color: #56a8f1;
    }
  }

  .line {
    height: 1px;
    background-color: #f1f1f1;
  }

  .btns {
    display: flex;
    padding-top: 20px;
    justify-content: center;

    .van-button {
      width: 160px;
      height: 44px;
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .btns_item {
    width: 140px;
    height: 29px;
    border-radius: 27px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    line-height: 1;
    margin-right: 15px;
    border: 1px solid #209dff;
    background-color: #209dff;
  }

  .btns_item_2 {
    background-image: none;
    color: #209dff;
    margin-right: 0;
    border-color: #fff;
    background-color: #fff;
  }
}

.share-layer {
  position: fixed;
  top: 20%;
  right: 2px;
  z-index: 100;

  .share-icon {
    color: #fff;
    font-size: 24px;
    padding: 2px 4px;
    border-radius: 50%;
    background-color: #979797;
  }

  .text {
    color: #333;
    font-size: 10px;
    padding-top: 5px;
    text-align: center;
  }
}
</style>